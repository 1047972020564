import Button from '@main/ui/components/Button';
import PageLayout from '@main/ui/components/PageLayout';
import { Controller } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';
import AiTerms from '@/components/AiTerms';
import SuggestPopupInput from '@/components/SuggestPopupInput';
import useHook from './hook';
var Component = function (props) {
    var title = '練習記録の編集';
    var _a = useHook(props), submit = _a.submit, control = _a.control, deletePractice = _a.deletePractice, submitButtonState = _a.submitButtonState, recentPracticePlaceNames = _a.recentPracticePlaceNames;
    return (<PageLayout singleColumn caption={title} breadcrumbs={[
            { caption: 'トップ', link: '/' },
            { caption: '活動履歴', link: '/activities' },
            { caption: '練習記録の編集' },
        ]}>
      <form onSubmit={submit}>
        <div className="mb-10 grid gap-y-5 rounded border border-solid border-gray-300 p-5">
          <Controller name="date" control={control} render={function (_a) {
            var _b = _a.field, ref = _b.ref, value = _b.value, onChange = _b.onChange, error = _a.fieldState.error;
            return (<div>
                <p className="mb-0.5 text-base text-gray-700">練習日</p>

                <input ref={ref} type="date" value={value} onChange={onChange} className="block h-auto w-full overflow-y-hidden rounded-sm !border-none !bg-gray-100 py-1 px-2 text-base !shadow-none outline-none"/>

                {error && (<div className="mt-1 text-sm text-red-500">
                    {error.message}
                  </div>)}
              </div>);
        }}/>

          <Controller name="place" control={control} render={function (_a) {
            var _b = _a.field, ref = _b.ref, value = _b.value, onChange = _b.onChange, error = _a.fieldState.error;
            return (<div>
                <p className="mb-0.5 text-base text-gray-700">練習場所</p>

                <SuggestPopupInput ref={ref} value={value} onChange={onChange} placeholder="（例）シェークハンズクラブ" suggestions={recentPracticePlaceNames} className="block h-auto w-full overflow-y-hidden rounded-sm !border-none !bg-gray-100 py-1 !px-2 !text-base !shadow-none outline-none"/>

                {error && (<div className="mt-1 text-sm text-red-500">
                    {error.message}
                  </div>)}
              </div>);
        }}/>

          <Controller name="comment" control={control} render={function (_a) {
            var _b = _a.field, ref = _b.ref, value = _b.value, onChange = _b.onChange, error = _a.fieldState.error;
            return (<div>
                <p className="mb-0.5 text-base text-gray-700">コメント</p>

                <TextareaAutosize ref={ref} value={value} rows={5} minRows={5} onChange={onChange} placeholder="練習の内容・良かったこと・反省点など、ご自由に入力ください。" className="block h-auto w-full resize-none overflow-y-hidden rounded-sm !border-none !bg-gray-100 py-1 px-2 text-base !shadow-none outline-none"/>

                {error && (<div className="mt-1 text-sm text-red-500">
                    {error.message}
                  </div>)}
              </div>);
        }}/>

          <div className="flex flex-col items-center pt-4">
            <Button type="submit" caption="保存する" state={submitButtonState}/>

            <div className="mt-5">
              <AiTerms />
            </div>
          </div>

          <div className="flex justify-center">
            <button type="button" onClick={deletePractice} className="py-1 px-2 text-base text-red-500 underline active:text-red-600">
              この記録を削除する
            </button>
          </div>
        </div>

        <div className="flex justify-center">
          <a href="/activities" className="py-1 px-2 text-base text-gray-500 underline active:text-gray-600">
            活動履歴に戻る
          </a>
        </div>
      </form>
    </PageLayout>);
};
export default Component;
