import { z } from 'zod';
export var propsSchema = z.object({
    practice: z.object({
        id: z.string(),
        date: z.string(),
        placeName: z.string(),
        comment: z.string(),
    }),
    recentPracticePlaceNames: z.array(z.string()),
});
