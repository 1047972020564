import { z } from 'zod';
export var practiceSchema = z.object({
    id: z.string(),
    date: z.string(),
    placeName: z.string().nullable(),
    comment: z.string().nullable(),
    feedbackCreatedAt: z.string().nullable(),
    feedbackComment: z.string().nullable(),
    feedbackKeywords: z.array(z.string()).nullable(),
});
