var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable no-console */
import { useToast } from '@chakra-ui/react';
import { useEffect, useMemo, useCallback } from 'react';
import { graphQlClient } from '@/utils/graphql';
import { useRouter } from '@/utils/router';
import { propsSchema } from './props';
var useHook = function (props) {
    var toast = useToast();
    var router = useRouter();
    var _a = propsSchema.parse(props), practice = _a.practice, completedArticleIds = _a.completedArticleIds, recommendedArticles = _a.recommendedArticles;
    var feedback = useMemo(function () {
        if (practice.feedbackCreatedAt === null ||
            practice.feedbackComment === null ||
            practice.feedbackKeywords === null) {
            return null;
        }
        return {
            createdAt: practice.feedbackCreatedAt,
            comment: practice.feedbackComment,
            keywords: practice.feedbackKeywords,
        };
    }, [practice]);
    var getIsArticleCompleted = useCallback(function (articleId) { return completedArticleIds.includes(articleId); }, [completedArticleIds]);
    // URLのクエリパラメタに応じてToastを表示する
    useEffect(function () {
        var url = new URL(window.location.href);
        if (url.searchParams.has('created')) {
            url.searchParams.delete('created');
            window.history.replaceState(null, '', url.toString());
            setTimeout(function () {
                toast({
                    title: '保存しました！',
                    position: 'top-right',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            }, 1000);
        }
        if (url.searchParams.has('updated')) {
            url.searchParams.delete('updated');
            window.history.replaceState(null, '', url.toString());
            setTimeout(function () {
                toast({
                    title: '更新しました！',
                    position: 'top-right',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            }, 1000);
        }
    }, [toast]);
    // 非同期で生成されるAIフィードバックが生成されていたら表示する
    // シンプルさを優先し、準備完了となったらページを再読み込みすることで表示する仕様としている
    useEffect(function () {
        var interval = 5000;
        var retryLeft = 30;
        var timer = null;
        var polling = function () { return __awaiter(void 0, void 0, void 0, function () {
            var result;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, graphQlClient.authPremiumPracticesShowPractice({
                            id: practice.id,
                        })];
                    case 1:
                        result = _b.sent();
                        if (((_a = result.practice) === null || _a === void 0 ? void 0 : _a.feedbackCreatedAt) !== null) {
                            console.log('AIフィードバックが届いたのでポーリング終了');
                            router.reload();
                            return [2 /*return*/];
                        }
                        if (retryLeft === 0) {
                            console.log('リトライ回数の上限に達したのでポーリングを終了');
                            return [2 /*return*/];
                        }
                        retryLeft--;
                        console.log('ポーリングを予約');
                        timer = window.setTimeout(function () { return void polling(); }, interval);
                        return [2 /*return*/];
                }
            });
        }); };
        if (practice.feedbackCreatedAt === null) {
            timer = window.setTimeout(function () { return void polling(); }, interval);
        }
        return function () {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [practice]);
    return { practice: practice, feedback: feedback, recommendedArticles: recommendedArticles, getIsArticleCompleted: getIsArticleCompleted };
};
export default useHook;
