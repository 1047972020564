import { useCallback } from 'react';
// Turbolinksへの依存はここに集約する
export var useRouter = function () {
    var push = useCallback(function (path) { return Turbolinks.visit(path); }, []);
    var reload = useCallback(function () { return Turbolinks.visit(window.location.href); }, []);
    return {
        push: push,
        reload: reload,
    };
};
