import { faCalendar, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { faCircleNotch, faLocationDot, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ArticleListItem from '@main/ui/components/ArticleListItem';
import PageLayout from '@main/ui/components/PageLayout';
import dayjs from '@/utils/dayjs';
import useHook from './hook';
var Component = function (props) {
    var title = '練習記録';
    var _a = useHook(props), practice = _a.practice, feedback = _a.feedback, recommendedArticles = _a.recommendedArticles, getIsArticleCompleted = _a.getIsArticleCompleted;
    return (<PageLayout caption={title} breadcrumbs={[
            { caption: 'トップ', link: '/' },
            { caption: '活動履歴', link: '/activities' },
            { caption: '練習記録' },
        ]}>
      <div className="flex items-start">
        {/* v1では非表示にする */}
        <div className="mr-10 hidden w-[330px]">
          <div className="rounded border border-solid border-gray-300">
            {Array.from({ length: 10 }).map(function (_, index) { return (<div key={index} className="border-b border-solid border-gray-300 p-3 last:border-b-0">
                <p className="line-clamp-2 mb-1.5 text-sm">
                  今日はサーブの安定性を中心に練習した。特にフォアハンドサーブの回転量を増やすことを意識してやった。
                </p>

                <div className="flex items-center space-x-2">
                  {[
                { icon: faCalendar, text: '2024/11/3' },
                { icon: faLocationDot, text: 'シェークハンズクラブ' },
            ].map(function (item) { return (<div key={item.text} className="flex items-center text-sm leading-none">
                      <FontAwesomeIcon fixedWidth icon={item.icon} className="mr-0.5 text-gray-500"/>
                      {item.text}
                    </div>); })}
                </div>
              </div>); })}
          </div>
        </div>

        <div className="mx-auto grid max-w-[700px] flex-1 gap-y-6">
          <div className="rounded border border-solid border-gray-300 px-5 py-6">
            <div className="flex items-center justify-between border-b border-solid border-gray-300 pb-4">
              <div className="grid gap-y-3">
                {[
            {
                icon: faCalendar,
                text: dayjs(practice.date).tz().format('YYYY/M/D'),
            },
            { icon: faLocationDot, text: practice.placeName || '未登録' },
        ].map(function (item) { return (<div key={item.text} className="flex items-center text-sm leading-none text-gray-700">
                    <FontAwesomeIcon fixedWidth icon={item.icon} className="mr-1 text-gray-600"/>
                    {item.text}
                  </div>); })}
              </div>

              <a href={"/practices/".concat(practice.id, "/edit")} className="flex items-center rounded border border-solid border-gray-300 px-2 py-1.5 text-sm text-gray-700 active:bg-gray-100">
                <FontAwesomeIcon fixedWidth icon={faPenToSquare} className="mr-1 text-gray-600"/>
                編集する
              </a>
            </div>

            <div className="pt-4">
              <div className="mb-1 text-sm text-gray-500">振り返り</div>
              <div className="mb-4 whitespace-pre-wrap text-base text-gray-700">
                {practice.comment}
              </div>

              <div className="rounded bg-blue-100 p-4 text-base text-blue-800">
                <div className="mb-2 text-base font-bold text-blue-800">
                  シェークハンズAIからのフィードバック（Beta）
                </div>

                {feedback ? (<div className="whitespace-pre-wrap text-base text-blue-800">
                    {feedback.comment}
                  </div>) : (<div className="mt-2 leading-none">
                    <FontAwesomeIcon spin fixedWidth icon={faCircleNotch} className="mr-1 text-blue-500"/>
                    AIがフィードバックを作成中...
                  </div>)}
              </div>
            </div>
          </div>

          {/* <div>
          <div className="text-lg font-bold mb-1 text-gray-700">
            関連性の高い練習記録
          </div>
        </div> */}

          {recommendedArticles.length > 0 && (<div>
              <div className="mb-1 text-lg font-bold text-gray-700">
                おすすめの卓球レッスン動画
              </div>

              <div>
                {recommendedArticles.map(function (article) { return (<ArticleListItem id={article.id} key={article.id} href={"/articles/".concat(article.id)} length={article.length} title={article.title} coachName={article.coachName} thumbnailUrl={article.thumbnailUrl} isCompleted={getIsArticleCompleted(article.id)}/>); })}
              </div>
            </div>)}
        </div>
      </div>
    </PageLayout>);
};
export default Component;
