import { Combobox, ComboboxInput, ComboboxOptions, ComboboxOption, } from '@headlessui/react';
import { forwardRef, useCallback, useMemo } from 'react';
var Component = forwardRef(function (_a, ref) {
    var value = _a.value, onChange = _a.onChange, className = _a.className, suggestions = _a.suggestions, placeholder = _a.placeholder;
    var normalizeText = useCallback(function (text) { return text.trim().replace(/\s+/g, ' ').toLowerCase(); }, []);
    var normalizedValue = useMemo(function () { return normalizeText(value); }, [value]);
    var processedSuggestions = useMemo(function () {
        return suggestions.map(function (s) { return ({
            label: s,
            value: s,
            normalizedValue: normalizeText(s),
        }); });
    }, [suggestions]);
    var filteredSuggestions = useMemo(function () {
        var maxSuggestions = 5;
        // 何も入力されていなければ、先頭から指定件数を表示する
        if (value.length === 0) {
            return processedSuggestions.slice(0, maxSuggestions);
        }
        return processedSuggestions
            .filter(function (s) { return s.normalizedValue.includes(normalizedValue); })
            .slice(0, maxSuggestions);
    }, [normalizedValue, processedSuggestions]);
    return (<div className="relative">
        <Combobox immediate value={value} onChange={function (newValue) { return onChange(newValue !== null && newValue !== void 0 ? newValue : ''); }}>
          <ComboboxInput ref={ref} className={className} placeholder={placeholder} onChange={function (event) { return onChange(event.target.value); }}/>

          {filteredSuggestions.length > 0 && (<ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5">
              {filteredSuggestions.map(function (suggestion, index) { return (<ComboboxOption key={index} value={suggestion.value} className={function (_a) {
                    var selected = _a.selected;
                    return "relative cursor-pointer select-none py-2 px-4 ".concat(selected ? 'bg-gray-100' : 'text-gray-900');
                }}>
                  {suggestion.label}
                </ComboboxOption>); })}
            </ComboboxOptions>)}
        </Combobox>
      </div>);
});
export default Component;
