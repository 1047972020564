var Component = function () {
    return (<div className="text-sm leading-normal text-slate-600">
      本機能は生成AIを利用しています。当サービスでの生成AIの利用に関する詳細は
      <a target="_blank" className="text-blue-500 underline" href="https://shands.notion.site/AI-164b70293879809eae22ff80f4a9613c">
        こちら
      </a>
      をご覧ください。
    </div>);
};
export default Component;
